import {Component, Input, OnInit} from '@angular/core';
import {GallerySearchParams} from '../gallery-search-params';
@Component({
  selector: 'hpf-gallery-filters',
  templateUrl: './gallery-filters.component.html'
})
export class GalleryFiltersComponent implements OnInit {
  /** The filters */
  @Input() searchParams: GallerySearchParams = new GallerySearchParams();
  /** Denotes if the page number should be set to 0 when filters change */
  @Input() resetPage = false;
  /** Constructor */
  constructor() {}
  /** Init */
  ngOnInit() {
  }
  /** Called when an input is changed */
  onChange() {
    if (this.resetPage) {
        this.searchParams.props._page = 0;
    }
    this.searchParams.next();
  }
  /** Clear all filters */
  public resetSearchParams() {
    this.searchParams.fromObject({});
  }
}
