<div class="w-100 min-h-100 d-flex flex-column flex-nowrap">
  <section class="w-100 min-h-100 d-flex flex-row flex-shrink-1 flex-grow-1">
    <nav id="navmainMenu" *ngIf="displayHeader" class="d-flex flex-column justify-content-between">




      <ul id="mainMenu" nz-menu [nzMode]="'inline'">

        <li nz-menu-item routerLink="/home" routerLinkActive="ant-menu-item-selected" nzIcon="home">
          {{ 'home_link' | translate }}
        </li>


        <!--Sites-->


        <li nz-submenu nzTitle="{{('plateau_common_name'|translate).toUpperCase()}}" nzIcon="compass">
          <ul>
            <li nz-menu-item [routerLink]="['/plateau']" routerLinkActive="ant-menu-item-selected">

              {{'plateau_common_name'|translate}}

            </li>
            <li nz-menu-item [routerLink]="['/route']" routerLinkActive="ant-menu-item-selected">
              {{'route_common_name'|translate}}
            </li>
            <li nz-menu-item [routerLink]="['/category']" routerLinkActive="ant-menu-item-selected">
              {{'category_common_name'|translate}}
            </li>

          </ul>
        </li>

        <!--POI-->
        <li nz-submenu nzTitle="{{'common_POI'|translate}}" nzIcon="environment">
          <ul>

            <li nz-menu-item [routerLink]="['/point-of-interest']" routerLinkActive="ant-menu-item-selected">

              {{'point-of-interest_common_name'|translate}}

            </li>

            <li nz-menu-item [routerLink]="['/marker']" routerLinkActive="ant-menu-item-selected">
              {{'marker_common_name'|translate}}
            </li>

          </ul>
        </li>
        <!--multimedia-->

        <li nz-submenu nzTitle="{{'common_multimedia'|translate}}" nzIcon="play-square">
          <ul>
            <li nz-menu-item [routerLink]="['/image']" routerLinkActive="ant-menu-item-selected">

              {{'image_common_name'|translate}}

            </li>

            <li nz-menu-item [routerLink]="['/audio']" routerLinkActive="ant-menu-item-selected">
              {{'audio_common_name'|translate}}
            </li>
            <li nz-menu-item [routerLink]="['/video']" routerLinkActive="ant-menu-item-selected">

              {{'video_common_name'|translate}}

            </li>

            <li nz-menu-item [routerLink]="['/diaporama']" routerLinkActive="ant-menu-item-selected">
              {{'diaporama_common_name'|translate}}
            </li>
            <li nz-menu-item [routerLink]="['/gallery']" routerLinkActive="ant-menu-item-selected">
              {{'gallery_common_name'|translate}}
            </li>
          </ul>
        </li>

        <!--General-->
        <li nz-submenu nzTitle="{{'common_general'|translate}}" nzIcon="global">
          <ul>
            <li nz-menu-item [routerLink]="['/about']" routerLinkActive="ant-menu-item-selected">
              {{'about_common_name'|translate}}
            </li>

            <li nz-menu-item [routerLink]="['/credit']" routerLinkActive="ant-menu-item-selected">
              {{'credit_common_name'|translate}}
            </li>


          </ul>
        </li>

        <!--Accessibilite-->
        <li nz-submenu nzTitle="{{'common_accessibilite'|translate}}" nzIcon="smile">
          <ul>
            <li nz-menu-item [routerLink]="['/audio-description']" routerLinkActive="ant-menu-item-selected">

              {{'audio-description_common_name'|translate}}

            </li>

          </ul>
        </li>




        <!--Admin-->
        <li nz-submenu nzTitle="{{'common_administration'|translate}}" nzIcon="team">
          <ul>
            <li nz-menu-item [routerLink]="['/user']" routerLinkActive="ant-menu-item-selected">
              {{'user_common_name'|translate}}
            </li>
            <li nz-menu-item *appRestrictedByScope="{
      model: 'role',
      action: 'read'
    }" class="mt-0 mb-3" [routerLink]="['/role']" routerLinkActive="ant-menu-item-selected">

              {{ 'role_common_name' | translate }}
            </li>
            <li nz-menu-item *appRestrictedByScope="{model: 'role-permission',action: 'read'}" class="mt-0 mb-3"
              [routerLink]="['/role-permission']" routerLinkActive="ant-menu-item-selected">

              {{ 'role-permission_common_name' | translate }}
            </li>
            <li nz-menu-item [routerLink]="['/language']" routerLinkActive="ant-menu-item-selected">
              {{'language_common_name'|translate}}
            </li>

            <li nz-menu-item *appRestrictedByScope="{
      model: 'activity_log',
      action: 'read'
    }" class="mt-0 mb-3" [routerLink]="['/activity-log']" routerLinkActive="ant-menu-item-selected">

              {{ 'activity-log_common_name' | translate }}
            </li>

          </ul>
        </li>


      </ul>
      <ul nz-menu [nzMode]="'vertical'" [nzSelectable]="false">
        <li nz-menu-item class="lang">
          <span class="border-right pr-3" (click)="setLang('fr')"
            [class.lang-active]="currentLang === 'fr'">{{ 'lang-FR' | translate }}</span>
          <span class="pl-3" (click)="setLang('en')"
            [class.lang-active]="currentLang === 'en'">{{ 'lang-EN' | translate }}</span>
        </li>

        <li nz-menu-item routerLink="/user/{{ self.getId() }}" routerLinkActive="ant-menu-item-selected">
          <i nz-icon nzType="user" nzTheme="outline" class="mr-2"></i>{{ self.getName() }}
        </li>
        <li nz-menu-item (click)="logout()">
          <span><i nz-icon nzType="logout" nzTheme="outline"
              class="mr-2"></i>{{ 'header_action-logout' | translate }}</span>
        </li>
      </ul>
    </nav>
    <main class="w-100 flex-shrink-1 flex-grow-1" [class.no-header]="!displayHeader">
      <router-outlet #o="outlet"></router-outlet>
    </main>
  </section>
</div>

<app-quick-form></app-quick-form>
<app-image-modal></app-image-modal>
