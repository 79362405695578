import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {Subscription as RxJsSubscription} from 'rxjs';
import {ErrorService} from '@app/services';
import {Gallery} from '../../gallery';
import {GalleryService} from '../../gallery.service';
@Component({
  selector: 'hpf-gallery-edit',
  templateUrl: './gallery-edit.component.html'
})
export class GalleryEditComponent implements OnInit, OnDestroy {
  /** The gallery */
  gallery = new Gallery();
  /** The gallery */
  private id: string;
  /** The id subscription */
  private idSubscription: RxJsSubscription;
  /** Constructor */
  constructor(private route: ActivatedRoute,
              private router: Router,
              private errorService: ErrorService,
              private galleryService: GalleryService) {}
  /** Init */
  ngOnInit() {
    // Get the gallery after reading the id
    this.idSubscription = this.route.params.subscribe(params => {
        this.id = params['id'];
        this.refresh();
    });
  }
  /** Destroy */
  ngOnDestroy() {
    this.idSubscription.unsubscribe();
  }
  /** Fetch gallery from API */
  private refresh() {
    this.galleryService.get(this.id, { _populate: ['*'] })
      .then((gallery: Gallery) => {
        this.gallery.fromObject(gallery.toObject());
      })
      .catch((error) => this.errorService.handle(error));
  }
  /** Called when the gallery is updated */
  onUpdate(): void {
    this.refresh();
  }
  /** Called when the gallery is deleted */
  onDelete(): void {
    this.router.navigateByUrl('/gallery')
      .catch((error) => this.errorService.handle(error));
  }
}
