import {Component, Input, OnInit} from '@angular/core';
import {ErrorService} from '@app/services';
import {GallerySearchParams} from '../gallery-search-params';
import {GalleryService} from '../gallery.service';
@Component({
  selector: 'hpf-gallery-count',
  templateUrl: './gallery-count.component.html'
})
export class GalleryCountComponent implements OnInit {
  /** The filters */
  @Input() searchParams: GallerySearchParams = new GallerySearchParams();
  /** Count result */
  count: number;
  /** Load flag */
  loaded = false;
  /** Constructor */
  constructor(private galleryService: GalleryService,
              private errorService: ErrorService) {}
  /** Init */
  ngOnInit() {
    this.galleryService.count(this.searchParams.toObject())
    .then((count) => {
      this.count = count;
      this.loaded = true;
    })
    .catch((error) => this.errorService.handle(error))
  }
}
