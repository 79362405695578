import {Injectable} from '@angular/core';
import {Gallery, GalleryInterface, GalleryPayload} from './gallery';
import {GallerySearchParamsInterface} from './gallery-search-params';
import {BaseModelService} from '@app/abstracts';
@Injectable()
export class GalleryService extends BaseModelService< Gallery, GalleryInterface, GalleryPayload, GallerySearchParamsInterface> {
  /** @inheritDoc */
  protected path(): string {
    return 'gallery';
  }
  /** @inheritDoc */
  protected new(object: GalleryInterface): Gallery {
    return new Gallery(object);
  }
}
