import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorService} from '@app/services';
import {Gallery} from '../../gallery';

@Component({
  selector: 'hpf-gallery-create',
  templateUrl: './gallery-create.component.html'
})
export class GalleryCreateComponent implements OnInit {
  /** The gallery */
  gallery = new Gallery();

  /** Constructor */
  constructor(private router: Router,
              private errorService: ErrorService) {
  }
  /** Init */
  ngOnInit() {
  }
  /** Called when the gallery is created */
  onCreate(gallery: Gallery): void {
    this.router.navigateByUrl(`/gallery/${gallery.getId()}`)
      .catch((error) => this.errorService.handle(error));
  }

}
