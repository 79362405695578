import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
import { Language, LanguageInterface } from '../language/language';
import { Image, ImageInterface } from '../image/image';
import {
	AudioDescription,
	AudioDescriptionInterface,
} from '../audio-description/audio-description';
export interface PlateauInterface extends BaseModelInterface {
	created_at: number | Date;
	name: string;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	languages: (string | Language | LanguageInterface)[];
	min_lat: number;
	max_lat: number;
	min_lng: number;
	max_lng: number;
	logo?: string | Image | ImageInterface;
	image_intro: string | Image | ImageInterface;
	panels?: (string | TextTranslated | TextTranslatedInterface)[];
	audio_descriptions?: (
		| string
		| AudioDescription
		| AudioDescriptionInterface
	)[];
}
export interface PlateauPayload {
	name: string;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	languages: (string | Language | LanguageInterface)[];
	min_lat: number;
	max_lat: number;
	min_lng: number;
	max_lng: number;
	logo?: string | Image | ImageInterface;
	image_intro: string | Image | ImageInterface;
	panels?: (string | TextTranslated | TextTranslatedInterface)[];
	audio_descriptions?: (
		| string
		| AudioDescription
		| AudioDescriptionInterface
	)[];
}
type PlateauPayloadKey = keyof PlateauPayload;
export class Plateau extends BaseModel<PlateauInterface, PlateauPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return `${this.props.name}`;
	}

	getTitle(): string {
		let lng = localStorage.getItem('lang');
		var tt = this.props.title.find(function (params: TextTranslated) {
			var l = params.props.lang as Language;
			return lng.toLowerCase() == l.props.name.toLowerCase();
		}) as TextTranslated;
		if (tt == null) {
			return '';
		}
		return tt.props.text;
	}
	/** Denotes if the instance of title has been populated */
	titleExists(): boolean {
		return (
			!!this.props &&
			this.props.title instanceof Array &&
			(<TextTranslated[]>this.props.title).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Denotes if the instance of languages has been populated */
	languagesExists(): boolean {
		return (
			!!this.props &&
			this.props.languages instanceof Array &&
			(<Language[]>this.props.languages).every((item) => {
				return item instanceof Language && item.exists();
			})
		);
	}
	/** Denotes if the instance of logo has been populated */
	logoExists(): boolean {
		return (
			!!this.props &&
			this.props.logo instanceof Image &&
			this.props.logo.exists()
		);
	}
	/** Denotes if the instance of image intro has been populated */
	imageIntroExists(): boolean {
		return (
			!!this.props &&
			this.props.image_intro instanceof Image &&
			this.props.image_intro.exists()
		);
	}
	/** Denotes if the instance of panels has been populated */
	panelsExists(): boolean {
		return (
			!!this.props &&
			this.props.panels instanceof Array &&
			(<TextTranslated[]>this.props.panels).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Denotes if the instance of audio descriptions has been populated */
	audioDescriptionsExists(): boolean {
		return (
			!!this.props &&
			this.props.audio_descriptions instanceof Array &&
			(<AudioDescription[]>this.props.audio_descriptions).every(
				(item) => {
					return item instanceof AudioDescription && item.exists();
				}
			)
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: PlateauInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.languages instanceof Array) {
			this.props.languages = (<LanguageInterface[]>(
				this.props.languages
			)).map((item) => {
				return typeof item === 'object' ? new Language(item) : item;
			});
		}
		if (this.props.logo !== null && typeof this.props.logo === 'object') {
			this.props.logo = new Image(<ImageInterface>this.props.logo);
		}
		if (typeof this.props.image_intro === 'object') {
			this.props.image_intro = new Image(
				<ImageInterface>this.props.image_intro
			);
		}
		if (this.props.panels instanceof Array) {
			this.props.panels = (<TextTranslatedInterface[]>(
				this.props.panels
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.audio_descriptions instanceof Array) {
			this.props.audio_descriptions = (<AudioDescriptionInterface[]>(
				this.props.audio_descriptions
			)).map((item) => {
				return typeof item === 'object'
					? new AudioDescription(item)
					: item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): PlateauInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.title === 'undefined') {
			props.title = [];
		}
		if (typeof props.logo === 'undefined') {
			props.logo = null;
		}
		if (typeof props.panels === 'undefined') {
			props.panels = [];
		}
		if (typeof props.audio_descriptions === 'undefined') {
			props.audio_descriptions = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.languages instanceof Array) {
			props.languages = (<Language[]>props.languages).map((item) => {
				return item instanceof Language ? item.toObject() : item;
			});
		}
		if (props.logo !== null && props.logo instanceof Image) {
			props.logo = props.logo.toObject();
		}
		if (props.image_intro instanceof Image) {
			props.image_intro = props.image_intro.toObject();
		}
		if (this.props.panels instanceof Array) {
			props.panels = (<TextTranslated[]>props.panels).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.audio_descriptions instanceof Array) {
			props.audio_descriptions = (<AudioDescription[]>(
				props.audio_descriptions
			)).map((item) => {
				return item instanceof AudioDescription
					? item.toObject()
					: item;
			});
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): PlateauPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as PlateauInterface);
		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: null;
		payload.languages = payload.languages
			? payload.languages.map((i) => this.extractId(i))
			: null;
		payload.logo = payload.logo ? this.extractId(payload.logo) : null;
		payload.image_intro = payload.image_intro
			? this.extractId(payload.image_intro)
			: null;
		payload.panels = payload.panels
			? payload.panels.map((i) => this.extractId(i))
			: null;
		payload.audio_descriptions = payload.audio_descriptions
			? payload.audio_descriptions.map((i) => this.extractId(i))
			: null;
		return payload as PlateauPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): PlateauPayloadKey[] {
		return [
			'name',
			'title',
			'languages',
			'min_lat',
			'max_lat',
			'min_lng',
			'max_lng',
			'logo',
			'image_intro',
			'panels',
			'audio_descriptions',
		];
	}
}
