import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {GallerySearchParams} from '../gallery-search-params';
import {GalleryService} from '../gallery.service';
import {Gallery} from '../gallery';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-gallery-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class GallerySelectComponent extends EntitySelectComponent< Gallery> {
  protected modelName = 'gallery';
  /**
   * Constructor
   * @param {GalleryService} galleryService
   * @param {ErrorService} errorService
   */
  constructor(private galleryService: GalleryService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Gallery)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Gallery[]>this.model).push(<Gallery>model);
          } else {
            this.model = <Gallery>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Gallery[]> {
      const params = new GallerySearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      return (await this.galleryService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Gallery> {
      return await this.galleryService.get(id);
  }
}
