import {Component} from '@angular/core';
import {
	EntitySelectComponent,
	BaseModel,
	BaseModelInterface,
} from '@app/abstracts';
import {ErrorService} from '@app/services';
import {DiaporamaSearchParams} from '../diaporama-search-params';
import {DiaporamaService} from '../diaporama.service';
import {Diaporama} from '../diaporama';
import { QuickFormService } from '../../../plugins/quick-form';
@Component({
  selector: 'hpf-diaporama-select',
  templateUrl: '../../../abstracts/entity-select/entity-select.component.html'
})
export class DiaporamaSelectComponent extends EntitySelectComponent< Diaporama> {
  protected modelName = 'diaporama';
  /**
   * Constructor
   * @param {DiaporamaService} diaporamaService
   * @param {ErrorService} errorService
   */
  constructor(private diaporamaService: DiaporamaService,
              protected errorService: ErrorService,
              protected quickFormService: QuickFormService) {
    super(errorService, quickFormService);
  }
  ngOnInit() {
		super.ngOnInit();
    // Refresh model in case of creation by quick form
		this.subscriptions.push(
			this.quickFormService.refresh.subscribe(
				(model: BaseModel<BaseModelInterface, {}>) => {
					if(!(model instanceof Diaporama)) return;
          if(this.multiple) {
            if (!this.model) this.model = [];
            (<Diaporama[]>this.model).push(<Diaporama>model);
          } else {
            this.model = <Diaporama>model;
          }
          this.refresh();
				}
			)
		);
	}
  /** @inheritDoc */
  protected async getList(): Promise< Diaporama[]> {
      const params = new DiaporamaSearchParams({
          _limit: this.resultsLimit,
          _page: this.currentPage
      });
      return (await this.diaporamaService.list(params.toObject())).items;
  }
  /** @inheritDoc */
  protected async getOne(id: string): Promise< Diaporama> {
      return await this.diaporamaService.get(id);
  }
}
