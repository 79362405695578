import {Component} from '@angular/core';
import {EntityListTopBarComponent} from '@app/abstracts';
import {GallerySearchParams} from '../gallery-search-params';

@Component({
  selector: 'hpf-gallery-list-top-bar',
  templateUrl: '../../../abstracts/entity-list-top-bar/entity-list-top-bar.component.html'
})
export class GalleryListTopBarComponent extends EntityListTopBarComponent< GallerySearchParams> {
}
 