import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PointOfInterestComponent } from './point-of-interest/point-of-interest/point-of-interest.component';
import { PointOfInterestCreateComponent } from './point-of-interest/views/point-of-interest-create/point-of-interest-create.component';
import { PointOfInterestEditComponent } from './point-of-interest/views/point-of-interest-edit/point-of-interest-edit.component';
import { PointOfInterestListComponent } from './point-of-interest/views/point-of-interest-list/point-of-interest-list.component';
import { UserComponent } from './user/user/user.component';
import { UserCreateComponent } from './user/views/user-create/user-create.component';
import { UserEditComponent } from './user/views/user-edit/user-edit.component';
import { UserListComponent } from './user/views/user-list/user-list.component';
import { VideoComponent } from './video/video/video.component';
import { VideoCreateComponent } from './video/views/video-create/video-create.component';
import { VideoEditComponent } from './video/views/video-edit/video-edit.component';
import { VideoListComponent } from './video/views/video-list/video-list.component';
import { AudioComponent } from './audio/audio/audio.component';
import { AudioCreateComponent } from './audio/views/audio-create/audio-create.component';
import { AudioEditComponent } from './audio/views/audio-edit/audio-edit.component';
import { AudioListComponent } from './audio/views/audio-list/audio-list.component';
import { TextTranslatedComponent } from './text-translated/text-translated/text-translated.component';
import { TextTranslatedCreateComponent } from './text-translated/views/text-translated-create/text-translated-create.component';
import { TextTranslatedEditComponent } from './text-translated/views/text-translated-edit/text-translated-edit.component';
import { TextTranslatedListComponent } from './text-translated/views/text-translated-list/text-translated-list.component';
import { ImageComponent } from './image/image/image.component';
import { ImageCreateComponent } from './image/views/image-create/image-create.component';
import { ImageCreateBulkComponent } from './image/views/image-create-bulk/image-create-bulk.component';
import { ImageEditComponent } from './image/views/image-edit/image-edit.component';
import { ImageListComponent } from './image/views/image-list/image-list.component';
import { LanguageComponent } from './language/language/language.component';
import { LanguageCreateComponent } from './language/views/language-create/language-create.component';
import { LanguageEditComponent } from './language/views/language-edit/language-edit.component';
import { LanguageListComponent } from './language/views/language-list/language-list.component';
import { CategoryComponent } from './category/category/category.component';
import { CategoryCreateComponent } from './category/views/category-create/category-create.component';
import { CategoryEditComponent } from './category/views/category-edit/category-edit.component';
import { CategoryListComponent } from './category/views/category-list/category-list.component';
import { RouteComponent } from './route/route/route.component';
import { RouteCreateComponent } from './route/views/route-create/route-create.component';
import { RouteEditComponent } from './route/views/route-edit/route-edit.component';
import { RouteListComponent } from './route/views/route-list/route-list.component';
import { PlateauComponent } from './plateau/plateau/plateau.component';
import { PlateauCreateComponent } from './plateau/views/plateau-create/plateau-create.component';
import { PlateauEditComponent } from './plateau/views/plateau-edit/plateau-edit.component';
import { PlateauListComponent } from './plateau/views/plateau-list/plateau-list.component';
import { AudioDescriptionComponent } from './audio-description/audio-description/audio-description.component';
import { AudioDescriptionCreateComponent } from './audio-description/views/audio-description-create/audio-description-create.component';
import { AudioDescriptionEditComponent } from './audio-description/views/audio-description-edit/audio-description-edit.component';
import { AudioDescriptionListComponent } from './audio-description/views/audio-description-list/audio-description-list.component';
import { DiaporamaComponent } from './diaporama/diaporama/diaporama.component';
import { DiaporamaCreateComponent } from './diaporama/views/diaporama-create/diaporama-create.component';
import { DiaporamaEditComponent } from './diaporama/views/diaporama-edit/diaporama-edit.component';
import { DiaporamaListComponent } from './diaporama/views/diaporama-list/diaporama-list.component';
import { AboutComponent } from './about/about/about.component';
import { AboutCreateComponent } from './about/views/about-create/about-create.component';
import { AboutEditComponent } from './about/views/about-edit/about-edit.component';
import { AboutListComponent } from './about/views/about-list/about-list.component';
import { CreditComponent } from './credit/credit/credit.component';
import { CreditCreateComponent } from './credit/views/credit-create/credit-create.component';
import { CreditEditComponent } from './credit/views/credit-edit/credit-edit.component';
import { CreditListComponent } from './credit/views/credit-list/credit-list.component';
import { MarkerComponent } from './marker/marker/marker.component';
import { MarkerCreateComponent } from './marker/views/marker-create/marker-create.component';
import { MarkerBulkCreateComponent } from './marker/views/marker-create-bulk/marker-create-bulk.component';
import { MarkerEditComponent } from './marker/views/marker-edit/marker-edit.component';
import { MarkerListComponent } from './marker/views/marker-list/marker-list.component';
import { CheckPermissionGuard } from '@app/guards';
import { GalleryComponent } from './gallery/gallery/gallery.component';
import { GalleryCreateComponent } from './gallery/views/gallery-create/gallery-create.component';
import { GalleryEditComponent } from './gallery/views/gallery-edit/gallery-edit.component';
import { GalleryListComponent } from './gallery/views/gallery-list/gallery-list.component';
const routes: Routes = [
	{
		path: 'point-of-interest',
		component: PointOfInterestComponent,
		children: [
			{
				path: '',
				component: PointOfInterestListComponent,
				data: {
					title: 'List point of interest',
					model: 'point_of_interest',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: PointOfInterestCreateComponent,
				data: {
					title: 'Create point of interest',
					model: 'point_of_interest',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: PointOfInterestEditComponent,
				data: {
					title: 'Edit point of interest',
					model: 'point_of_interest',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'user',
		component: UserComponent,
		children: [
			{
				path: '',
				component: UserListComponent,
				data: { title: 'List user', model: 'user', action: 'search' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: UserCreateComponent,
				data: { title: 'Create user', model: 'user', action: 'create' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: UserEditComponent,
				data: { title: 'Edit user', model: 'user', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'video',
		component: VideoComponent,
		children: [
			{
				path: '',
				component: VideoListComponent,
				data: { title: 'List video', model: 'video', action: 'search' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: VideoCreateComponent,
				data: {
					title: 'Create video',
					model: 'video',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: VideoEditComponent,
				data: { title: 'Edit video', model: 'video', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'audio',
		component: AudioComponent,
		children: [
			{
				path: '',
				component: AudioListComponent,
				data: { title: 'List audio', model: 'audio', action: 'search' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: AudioCreateComponent,
				data: {
					title: 'Create audio',
					model: 'audio',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: AudioEditComponent,
				data: { title: 'Edit audio', model: 'audio', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'text-translated',
		component: TextTranslatedComponent,
		children: [
			{
				path: '',
				component: TextTranslatedListComponent,
				data: {
					title: 'List text translated',
					model: 'text_translated',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: TextTranslatedCreateComponent,
				data: {
					title: 'Create text translated',
					model: 'text_translated',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: TextTranslatedEditComponent,
				data: {
					title: 'Edit text translated',
					model: 'text_translated',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'image',
		component: ImageComponent,
		children: [
			{
				path: '',
				component: ImageListComponent,
				data: { title: 'List image', model: 'image', action: 'search' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: ImageCreateComponent,
				data: {
					title: 'Create image',
					model: 'image',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'createbulk',
				component: ImageCreateBulkComponent,
				data: {
					title: 'Create mulitple image',
					model: 'image',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: ImageEditComponent,
				data: { title: 'Edit image', model: 'image', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'language',
		component: LanguageComponent,
		children: [
			{
				path: '',
				component: LanguageListComponent,
				data: {
					title: 'List language',
					model: 'language',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: LanguageCreateComponent,
				data: {
					title: 'Create language',
					model: 'language',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: LanguageEditComponent,
				data: {
					title: 'Edit language',
					model: 'language',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'category',
		component: CategoryComponent,
		children: [
			{
				path: '',
				component: CategoryListComponent,
				data: {
					title: 'List category',
					model: 'category',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: CategoryCreateComponent,
				data: {
					title: 'Create category',
					model: 'category',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: CategoryEditComponent,
				data: {
					title: 'Edit category',
					model: 'category',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'route',
		component: RouteComponent,
		children: [
			{
				path: '',
				component: RouteListComponent,
				data: { title: 'List route', model: 'route', action: 'search' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: RouteCreateComponent,
				data: {
					title: 'Create route',
					model: 'route',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: RouteEditComponent,
				data: { title: 'Edit route', model: 'route', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'plateau',
		component: PlateauComponent,
		children: [
			{
				path: '',
				component: PlateauListComponent,
				data: {
					title: 'List plateau',
					model: 'plateau',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: PlateauCreateComponent,
				data: {
					title: 'Create plateau',
					model: 'plateau',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: PlateauEditComponent,
				data: {
					title: 'Edit plateau',
					model: 'plateau',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'audio-description',
		component: AudioDescriptionComponent,
		children: [
			{
				path: '',
				component: AudioDescriptionListComponent,
				data: {
					title: 'List audio description',
					model: 'audio_description',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: AudioDescriptionCreateComponent,
				data: {
					title: 'Create audio description',
					model: 'audio_description',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: AudioDescriptionEditComponent,
				data: {
					title: 'Edit audio description',
					model: 'audio_description',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'diaporama',
		component: DiaporamaComponent,
		children: [
			{
				path: '',
				component: DiaporamaListComponent,
				data: {
					title: 'List diaporama',
					model: 'diaporama',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: DiaporamaCreateComponent,
				data: {
					title: 'Create diaporama',
					model: 'diaporama',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: DiaporamaEditComponent,
				data: {
					title: 'Edit diaporama',
					model: 'diaporama',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'about',
		component: AboutComponent,
		children: [
			{
				path: '',
				component: AboutListComponent,
				data: { title: 'List about', model: 'about', action: 'search' },
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: AboutCreateComponent,
				data: {
					title: 'Create about',
					model: 'about',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: AboutEditComponent,
				data: { title: 'Edit about', model: 'about', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'credit',
		component: CreditComponent,
		children: [
			{
				path: '',
				component: CreditListComponent,
				data: {
					title: 'List credit',
					model: 'credit',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: CreditCreateComponent,
				data: {
					title: 'Create credit',
					model: 'credit',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: CreditEditComponent,
				data: { title: 'Edit credit', model: 'credit', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},
	{
		path: 'marker',
		component: MarkerComponent,
		children: [
			{
				path: '',
				component: MarkerListComponent,
				data: {
					title: 'List marker',
					model: 'marker',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: MarkerCreateComponent,
				data: {
					title: 'Create marker',
					model: 'marker',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},

			{
				path: 'createbulk',
				component: MarkerBulkCreateComponent,
				data: {
					title: 'Create multiple marker',
					model: 'marker',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: MarkerEditComponent,
				data: { title: 'Edit marker', model: 'marker', action: 'read' },
				canActivate: [CheckPermissionGuard],
			},
		],
	},

	{
		path: 'gallery',
		component: GalleryComponent,
		children: [
			{
				path: '',
				component: GalleryListComponent,
				data: {
					title: 'List gallery',
					model: 'gallery',
					action: 'search',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: 'create',
				component: GalleryCreateComponent,
				data: {
					title: 'Create gallery',
					model: 'gallery',
					action: 'create',
				},
				canActivate: [CheckPermissionGuard],
			},
			{
				path: ':id',
				component: GalleryEditComponent,
				data: {
					title: 'Edit gallery',
					model: 'gallery',
					action: 'read',
				},
				canActivate: [CheckPermissionGuard],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ModelsRoutingModule {}
