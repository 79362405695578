import {Helpers} from '@app/shared';
import {BaseModel, BaseModelInterface} from '@app/abstracts';
import {Image, ImageInterface} from '../image/image';
import {TextTranslated, TextTranslatedInterface} from '../text-translated/text-translated';
export interface GalleryInterface extends BaseModelInterface {
  created_at: number | Date;
  name: string;
  images: (string | Image | ImageInterface)[];
  description?: (string | TextTranslated | TextTranslatedInterface)[];
}
export interface GalleryPayload {
  name: string;
  images: (string | Image | ImageInterface)[];
  description?: (string | TextTranslated | TextTranslatedInterface)[];
}
type GalleryPayloadKey = keyof GalleryPayload;
export class Gallery extends BaseModel< GalleryInterface, GalleryPayload> {
  /** Short function to get label of instance */
  getLabel(): string {
    return `${this.props.name}`;
  }
  /** Denotes if the instance of images has been populated */
  imagesExists(): boolean {
    return !!this.props &&
        this.props.images instanceof Array &&
        (< Image[]>this.props.images).every((item) => {
            return item instanceof Image && item.exists();
        });
  }
  /** Denotes if the instance of description has been populated */
  descriptionExists(): boolean {
    return !!this.props &&
        this.props.description instanceof Array &&
        (< TextTranslated[]>this.props.description).every((item) => {
            return item instanceof TextTranslated && item.exists();
        });
  }
  /** Populate the current instance from an object */
  fromObject(object: GalleryInterface): void {
    this.props = Object.assign({}, object);
        this.props.created_at = Helpers.convertToDate(this.props.created_at);
    if (this.props.images instanceof Array) {
        this.props.images = (< ImageInterface[]>this.props.images).map((item) => {
            return typeof item === 'object' ? new Image(item) : item;
        });
    }
    if (this.props.description instanceof Array) {
        this.props.description = (< TextTranslatedInterface[]>this.props.description).map((item) => {
            return typeof item === 'object' ? new TextTranslated(item) : item;
        });
    }
    this.next();
  }
  /** Convert the current instance to an object */
  toObject(): GalleryInterface {
    const props = Object.assign({}, this.props);
    if (typeof props.description === 'undefined') {
        props.description = [];
    }
        props.created_at = Helpers.convertToTimestamp(props.created_at);
    if (this.props.images instanceof Array) {
        props.images = (< Image[]>props.images).map((item) => {
            return item instanceof Image ? item.toObject() : item;
        });
    }
    if (this.props.description instanceof Array) {
        props.description = (< TextTranslated[]>props.description).map((item) => {
            return item instanceof TextTranslated ? item.toObject() : item;
        });
    }
    return props;
  }
  /** Convert an instance to an object to be sent to the API */
  toPayload(): GalleryPayload {
    const raw = this.toObject();
    const allowed = this.allowedKeys();
    const payload = Object.keys(raw)
      .filter(key => allowed.includes(< any>key))
      .reduce((o, k) => { o[k] = raw[k]; return o; }, {} as GalleryInterface);
    payload.images = payload.images ? payload.images.map(i => this.extractId(i)) : null;
    payload.description = payload.description ? payload.description.map(i => this.extractId(i)) : null;
    return payload as GalleryPayload;
  }
  /** List allowed keys to be sent to the API */
  protected allowedKeys(): GalleryPayloadKey[] {
  	return [
      'name',
      'images',
      'description',
  	];
  }
}
